import { Fragment } from 'react';

import { AccentBtn, BorderBtn, Btn } from '@playbooks/interface/buttons';
import { Form } from '@playbooks/interface/forms';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@playbooks/interface/modals';

const ModalForm = ({ title, model, open, setOpen, taskRunning, onSave, children }) => {
	// Methods
	const onToggle = () => setOpen(!open);

	const onSubmit = e => {
		e.preventDefault();
		onSave();
	};

	// Render
	return (
		<Fragment>
			<Modal open={open} onClose={() => setOpen(false)}>
				<Form onSubmit={onSubmit}>
					<ModalHeader onClose={() => setOpen(false)}>
						<ModalTitle>{title}</ModalTitle>
					</ModalHeader>
					<ModalBody>{children}</ModalBody>
					<ModalFooter>
						{model.id && (
							<AccentBtn prevIcon='trash' color='red-500' taskRunning={taskRunning === 'delete'}>
								Delete
							</AccentBtn>
						)}
						<Btn type='submit' taskRunning={taskRunning === 'save'}>
							Save
						</Btn>
					</ModalFooter>
				</Form>
			</Modal>
			<BorderBtn size='icon' icon='plus' onClick={onToggle} />
		</Fragment>
	);
};

export { ModalForm };
