import { Fragment } from 'react';

import { BorderBtn, Btn } from '@playbooks/interface/buttons';
import { Form } from '@playbooks/interface/forms';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@playbooks/interface/modals';

const ModalBulkForm = ({ title, model, open, setOpen, taskRunning, onClear, onSave, children }) => {
	// Methods
	const onOpen = () => setOpen(!open);

	const onSubmit = e => {
		e.preventDefault();
		onSave();
	};

	// Render
	return (
		<Fragment>
			<Modal open={open} onClose={() => setOpen(false)}>
				<Form onSubmit={onSubmit}>
					<ModalHeader onClose={() => setOpen(false)}>
						<ModalTitle>{`Edit ${model.length} ${title}`}</ModalTitle>
					</ModalHeader>
					<ModalBody>{children}</ModalBody>
					<ModalFooter>
						<Btn type='submit' taskRunning={taskRunning}>
							Save
						</Btn>
					</ModalFooter>
				</Form>
			</Modal>
			{model.length > 0 && (
				<Fragment>
					<BorderBtn size='icon' icon='xmark' color='red-500' onClick={onClear} />
					<BorderBtn size='icon' icon='floppy-disk' onClick={onOpen} />
				</Fragment>
			)}
		</Fragment>
	);
};

export { ModalBulkForm };
