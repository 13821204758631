import { useState } from 'react';

import { Drop, DropBtn, DropItem, DropList, DropMenu, DropToggle } from '@playbooks/interface/drops';

const PageSizePagination = ({ meta = {}, setPageSize }) => {
	const [open, setOpen] = useState(false);
	const pageSize = meta?.pageSize;
	const options = [10, 20, 50, 100, 250];

	// Methods
	const isActive = v => {
		return v === pageSize ? true : false;
	};

	// Render
	return (
		<Drop id='page_size_drop' open={open} setOpen={() => setOpen(false)}>
			<DropToggle type='border' active={open} onClick={() => setOpen(!open)}>
				{pageSize} Results
			</DropToggle>

			<DropMenu open={open}>
				<DropList>
					{options.map((option, i) => (
						<DropItem key={i}>
							<DropBtn prevIcon={isActive(option) ? 'check' : ''} onClick={() => setPageSize(option)}>
								{option} Results
							</DropBtn>
						</DropItem>
					))}
				</DropList>
			</DropMenu>
		</Drop>
	);
};

export { PageSizePagination };
